<template>
  <div class="cyber-container">
    <header :class="{ scrolled: scrolled, 'menu-open': menuActive }">
      <nav>
        <div class="logo">
          <img
            src="./static/Group s.svg?height=40&width=40"
            alt="LemonID Logo"
            class="logo-image"
          />
          <span class="logo-text">LemonID</span>
        </div>
        <button
          class="menu-toggle"
          @click="toggleMenu"
          :aria-expanded="menuActive"
          aria-controls="main-menu"
          aria-label="Toggle menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <ul id="main-menu" :class="{ active: menuActive }">
          <li v-for="item in menuItems" :key="item.id">
            <a :href="`#${item.id}`" @click="scrollTo(item.id)">{{
              item.label
            }}</a>
          </li>
        </ul>
      </nav>
    </header>

    <main>
      <section class="hero">
        <div class="hero-content">
          <h1>
            <span class="gradient-text">Secure</span> Your Digital Identity
          </h1>
          <p class="subtitle">
            Experience the future of Single Sign-On with LemonID
          </p>
          <button @click="showModal" class="cta-button">
            Get Started
            <ArrowRightIcon class="icon" />
          </button>
        </div>
        <div class="hero-image">
          <img
            src=".\static\sdffsd.svg?height=400&width=600"
            alt="LemonID Interface"
            class="floating-image"
          />
        </div>
      </section>

      <section id="features" class="features">
        <h2>Why Choose <span class="gradient-text">LemonID</span></h2>
        <div class="feature-grid">
          <div v-for="feature in features" :key="feature.title" class="feature">
            <component :is="feature.icon" class="feature-icon" />
            <h3>{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>
      </section>

      <section id="how-it-works" class="how-it-works">
        <h2>How It <span class="gradient-text">Works</span></h2>
        <div class="steps">
          <div v-for="(step, index) in steps" :key="index" class="step">
            <div class="step-number">{{ index + 1 }}</div>
            <h3>{{ step.title }}</h3>
            <p>{{ step.description }}</p>
          </div>
        </div>
      </section>

      <section id="cta" class="cta-section">
        <div class="cta-content">
          <h2>Ready to Secure Your Digital Future?</h2>
          <p>
            Join thousands of businesses that trust LemonID for their SSO needs.
          </p>
          <button @click="showModal" class="cta-button">
            Start Free Trial
            <ArrowRightIcon class="icon" />
          </button>
        </div>
      </section>
    </main>

    <footer>
      <div class="footer-content">
        <div class="footer-logo">
          <img
            src=".\static\Group s.svg?height=30&width=30"
            alt="LemonID Logo"
            class="logo-image"
          />
          <span class="logo-text">LemonID</span>
        </div>
        <div class="footer-links">
          <a
            href="https://github.com/Lemon-Corporation/private-policy.md"
            aria-label="Privacy Policy"
            >Privacy Policy</a
          >
          <a
            href="https://github.com/Lemon-Corporation/terms-of-service.md"
            aria-label="Terms of Service"
            >Terms of Service</a
          >
          <a href="https://t.me/lemoncorphelp" aria-label="Contact Us"
            >Contact Us</a
          >
        </div>
        <div class="social-links">
          <a href="https://github.com/Lemon-Corporation" aria-label="GitHub"
            ><GithubIcon
          /></a>
        </div>
      </div>
      <div class="copyright">
        &copy; {{ currentYear }} Lemon Corporation. All rights reserved.
      </div>
    </footer>

    <transition name="modal">
      <div v-if="modalVisible" class="modal" @click.self="closeModal">
        <div class="modal-content">
          <h2>Get Started with <span class="gradient-text">LemonID</span></h2>
          <p>Enter your email to start your free trial.</p>
          <form @submit.prevent="submitModal">
            <input
              type="email"
              v-model="modalEmail"
              placeholder="Enter your email"
              required
            />
            <button type="submit" class="submit-button">
              Start Free Trial
              <ArrowRightIcon class="icon" />
            </button>
          </form>
          <button
            @click="closeModal"
            class="close-modal"
            aria-label="Close modal"
          >
            &times;
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import {
  ArrowRightIcon,
  GithubIcon,
  ShieldIcon,
  ZapIcon,
  UsersIcon,
  LockIcon,
} from "lucide-vue-next";

const scrolled = ref(false);
const menuActive = ref(false);
const modalVisible = ref(false);
const modalEmail = ref("");
const currentYear = new Date().getFullYear();

const menuItems = [
  { id: "features", label: "Features" },
  { id: "how-it-works", label: "How It Works" },
  { id: "cta", label: "Get Started" },
];

const features = [
  {
    icon: ShieldIcon,
    title: "Advanced Security",
    description:
      "State-of-the-art encryption and multi-factor authentication keep your data safe.",
  },
  {
    icon: ZapIcon,
    title: "Lightning Fast",
    description:
      "Optimized performance ensures quick and seamless authentication across all your apps.",
  },
  {
    icon: UsersIcon,
    title: "User-Friendly",
    description:
      "Intuitive interface makes managing identities and access a breeze for admins and users alike.",
  },
  {
    icon: LockIcon,
    title: "Compliance Ready",
    description:
      "Built-in features to help you meet GDPR, HIPAA, and other regulatory requirements.",
  },
];

const steps = [
  {
    title: "Sign Up",
    description: "Create your LemonID account and set up your organization.",
  },
  {
    title: "Integrate",
    description:
      "Connect your apps and services using our simple API or pre-built integrations.",
  },
  {
    title: "Customize",
    description:
      "Tailor the login experience to match your brand and security needs.",
  },
  {
    title: "Go Live",
    description:
      "Roll out LemonID to your users and enjoy simplified, secure access management.",
  },
];

const toggleMenu = () => {
  menuActive.value = !menuActive.value;
};

const scrollTo = (elementId) => {
  const element = document.getElementById(elementId);
  if (element) {
    const headerOffset = 80;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
  menuActive.value = false;
};

const showModal = () => {
  modalVisible.value = true;
};

const closeModal = () => {
  modalVisible.value = false;
};

const submitModal = () => {
  alert(`Thank you! We'll send trial information to ${modalEmail.value}`);
  modalVisible.value = false;
  modalEmail.value = "";
};

const handleScroll = () => {
  scrolled.value = window.scrollY > 50;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

:root {
  --lemon-yellow: #f9e04b;
  --cyber-blue: #00ffff;
  --dark-bg: #0a0a0a;
  --light-text: #ffffff;
  --gray-text: #a0a0a0;
  --transition-speed: 0.3s;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
  background-color: var(--dark-bg);
  color: var(--light-text);
  line-height: 1.6;
}

.cyber-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

header {
  background: rgba(10, 10, 10, 0.8);
  padding: 1rem 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all var(--transition-speed) ease;
  backdrop-filter: blur(10px);
}

header.scrolled {
  background: rgba(10, 10, 10, 0.95);
  box-shadow: 0 2px 10px rgba(249, 224, 75, 0.1);
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 40px;
  width: auto;
  margin-right: 10px;
  margin-left: 10px;
}

.logo-text {
  font-size: 1.5rem;
  font-weight: 700;
  background: linear-gradient(45deg, var(--lemon-yellow), var(--cyber-blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  text-decoration: none;
  color: var(--light-text);
  transition: color var(--transition-speed) ease;
  font-weight: 500;
  margin-right: 10px;
}

nav ul li a:hover {
  color: var(--lemon-yellow);
}

.menu-toggle {
  display: none;
}

.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8rem 0 4rem;
  min-height: 100vh;
}

.hero-content {
  flex: 1;
  max-width: 600px;
}

.hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.gradient-text {
  background: linear-gradient(45deg, var(--lemon-yellow), var(--cyber-blue));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  font-size: 1.2rem;
  color: var(--gray-text);
  margin-bottom: 2rem;
}

.cta-button {
  background: linear-gradient(45deg, var(--lemon-yellow), var(--cyber-blue));
  color: var(--dark-bg);
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 30px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  display: inline-flex;
  align-items: center;
}

.cta-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(249, 224, 75, 0.3);
}

.cta-button .icon {
  margin-left: 0.5rem;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.floating-image {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  animation: float 6s ease-in-out infinite;
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

.features,
.how-it-works {
  padding: 6rem 0;
}

.features h2,
.how-it-works h2 {
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 3rem;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
}

.feature {
  background: rgba(255, 255, 255, 0.05);
  padding: 2rem;
  border-radius: 10px;
  text-align: center;
  transition: all var(--transition-speed) ease;
}

.feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 20px rgba(249, 224, 75, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  color: var(--lemon-yellow);
  margin-bottom: 1rem;
}

.feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.feature p {
  color: var(--gray-text);
}

.steps {
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
}

.step {
  flex: 1;
  text-align: center;
  padding: 0 1rem;
}

.step-number {
  background: linear-gradient(45deg, var(--lemon-yellow), var(--cyber-blue));
  color: var(--dark-bg);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 1rem;
  font-weight: 700;
}

.step h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.step p {
  color: var(--gray-text);
}

.cta-section {
  background: linear-gradient(
    45deg,
    rgba(249, 224, 75, 0.1),
    rgba(0, 255, 255, 0.1)
  );
  padding: 6rem 0;
  text-align: center;
}

.cta-content {
  max-width: 600px;
  margin: 0 auto;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-section p {
  color: var(--gray-text);
  margin-bottom: 2rem;
}

footer {
  background: rgba(255, 255, 255, 0.05);
  padding: 4rem 0 2rem;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.footer-logo {
  display: flex;
  align-items: center;
}

.footer-links a {
  color: var(--gray-text);
  text-decoration: none;
  margin-left: 1.5rem;
  transition: color var(--transition-speed) ease;
}

.footer-links a:hover {
  color: var(--lemon-yellow);
}

.social-links a {
  color: var(--gray-text);
  font-size: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  transition: color var(--transition-speed) ease;
}

.social-links a:hover {
  color: var(--lemon-yellow);
}

.copyright {
  text-align: center;
  color: var(--gray-text);
  font-size: 0.9rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.modal-content {
  background: var(--dark-bg);
  padding: 2rem;
  border-radius: 10px;
  max-width: 400px;
  width: 90%;
  position: relative;
}

.modal-content h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.modal-content p {
  color: var(--gray-text);
  margin-bottom: 1.5rem;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content input {
  padding: 0.8rem;
  margin-bottom: 1rem;
  border: 1px solid var(--gray-text);
  background: rgba(255, 255, 255, 0.05);
  color: var(--light-text);
  border-radius: 5px;
}

.submit-button {
  background: linear-gradient(45deg, var(--lemon-yellow), var(--cyber-blue));
  color: var(--dark-bg);
  padding: 0.8rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all var(--transition-speed) ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 20px rgba(249, 224, 75, 0.3);
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: var(--gray-text);
  font-size: 1.5rem;
  cursor: pointer;
  transition: color var(--transition-speed) ease;
}

.close-modal:hover {
  color: var(--lemon-yellow);
}

.modal-enter-active,
.modal-leave-active {
  transition: all var(--transition-speed) ease;
}

.modal-enter,
.modal-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

/* Responsive styles */
@media (max-width: 1024px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding-top: 6rem;
  }

  .hero-content {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .hero-image {
    justify-content: center;
  }

  .floating-image {
    max-width: 80%;
  }

  .steps {
    flex-wrap: wrap;
  }

  .step {
    flex-basis: 50%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 768px) {
  .menu-toggle {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
  }

  .menu-toggle span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: var(--light-text);
    margin: 5px 0;
    transition: all var(--transition-speed) ease;
  }

  .menu-open .menu-toggle span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .menu-open .menu-toggle span:nth-child(2) {
    opacity: 0;
  }

  .menu-open .menu-toggle span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  nav ul {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: rgba(10, 10, 10, 0.95);
    flex-direction: column;
    padding: 1rem 0;
  }

  nav ul.active {
    display: flex;
  }

  nav ul li {
    margin: 0;
    padding: 0.5rem 1rem;
  }

  .hero h1 {
    font-size: 2.5rem;
  }

  .feature-grid {
    grid-template-columns: 1fr;
  }

  .steps {
    flex-direction: column;
  }

  .step {
    flex-basis: 100%;
  }

  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-logo,
  .footer-links,
  .social-links {
    margin-bottom: 1rem;
  }

  .footer-links a,
  .social-links a {
    margin: 0 0.5rem;
  }
}

@media (max-width: 480px) {
  .hero h1 {
    font-size: 2rem;
  }

  .cta-section h2 {
    font-size: 2rem;
  }

  .feature,
  .step {
    padding: 1.5rem;
  }

  .modal-content {
    padding: 1.5rem;
  }
}

/* High-resolution screens */
@media (min-width: 1920px) {
  .cyber-container {
    max-width: 1600px;
  }

  .hero h1 {
    font-size: 4.5rem;
  }

  .subtitle {
    font-size: 1.5rem;
  }

  .feature-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* Print styles */
@media print {
  .hero-image,
  .cta-button,
  .menu-toggle,
  .modal {
    display: none;
  }

  body {
    background-color: white;
    color: black;
  }

  .gradient-text {
    background: none;
    -webkit-text-fill-color: black;
  }

  .feature,
  .step {
    page-break-inside: avoid;
  }
}
</style>
