<template>
  <router-view />
</template>

<style>
:root {
  --lemon-yellow: #f9e04b;
  --cyber-blue: #00ffff;
  --dark-bg: #0a0a0a;
  --light-text: #ffffff;
  --gray-text: #a0a0a0;
  --transition-speed: 0.3s;
}

@media screen and (min-width: 1201px) {
  ::-webkit-scrollbar-thumb {
    /* Укажи цвет скроллбара */
    background: linear-gradient(125deg, var(--lemon-yellow), var(--cyber-blue));
    /* Укажи радиус скругления скроллбара */
    border-radius: 10px;
  }
  ::-webkit-scrollbar {
    /* Укажи ширину вертикального скроллбара */
    width: 5px;
    /* Укажи высоту горизонтального скроллбара */
    height: 5px;
    /* Укажи цвет фона под скроллбаром */
    background: #00000;
  }
}
</style>

export default { head: { link: [ { rel: "icon", href:
require("./assets/logo.png") }, ] } }
